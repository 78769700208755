@use 'core/variables';

.modal-dialog {
    .alert-wrapper::part(content) {
        max-width: 90vw;
    }
}

// Ionic breakpoint for desktop modal
@media only screen and (min-height: 600px) and (min-width: variables.$breakpoint-md) {
    .rounded-modal::part(content) {
        --border-radius: 20px;
    }

    .small-modal::part(content) {
        --width: 500px;
        --height: 500px;
    }
}

.transparent-modal::part(content) {
    --background: rgba(255, 255, 255, 0.9);
}

.chat-popup-popover {
    pointer-events: none; // Allow interaction behind popover
    z-index: 1 !important; // Layer behind menus etc.

    &::part(content) {
        width: auto;
        right: 0;
        transform-origin: bottom right;
        top: unset;
        left: unset;
        bottom: 0;
        pointer-events: auto;
        overflow: hidden;
    }
}

.action-popover,
.list-popover {
    &::part(content) {
        width: auto;
    }
}

.person-popover {
    &::part(content) {
        --offset-y: -100px;
        --width: 300px;
    }
}

.now-next {
    &-popover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.9) 95%);
        animation-name: fade-in;
        animation-duration: 650ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;

        @media screen and (max-width: variables.$breakpoint-sm) {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 45%, rgba(0, 0, 0, 0.9) 95%);
        }

        &::part(content) {
            width: 100%;
            background: transparent;
            box-shadow: unset;
            top: unset;
            box-sizing: border-box;
            bottom: 0;
        }
    }
}

.meeting-time-picker-dialog {
    &::part(content) {
        --max-height: 700px;
        --max-width: 500px;
        height: 100%;

        @media (max-width: variables.$breakpoint-sm) {
            --max-height: initial;
            --max-width: initial;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.lightbox-image-dialog,
.lightbox-video-dialog {
    &::part(content) {
        background-color: #444;
        --max-height: calc(100vh - 32px);
        --max-width: calc(100vw - 32px);
        --width: 100%;
        --height: 100%;

        @media screen and (max-width: variables.$breakpoint-sm) {
            --max-height: 100%;
            --max-width: 100%;
            border-radius: 0; // Remove border radius to fill the corners
        }
    }
}

.map-popover__controller {
    --width: 350px;
    --max-height: 400px;
}

.map-popover__controller .popover-viewport {
    overflow-y: hidden;
}
.confirm-action-button {
    color: var(--ion-text-color) !important;
}
