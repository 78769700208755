$image-size: 8rem;
.video-call-dialog {
    img,
    .cc-initials {
        display: inline-block;
        width: $image-size;
        height: $image-size;
        position: relative;
        margin-inline-start: 50%;
        left: -4rem;
        border-radius: 50%;
        background-color: var(--cc-primary-color);
        color: var(--cc-text-on-primary-color);
        text-align: center;
        line-height: 4;
        font-weight: 800;
        font-size: 2rem;
        border: 2px solid var(--cc-accent-color);
    }

    h3,
    p {
        text-align: center;
    }

    p {
        margin: 0;
        font-size: 0.9rem;
    }

    .alert-wrapper::part(content) {
        width: 80vw;
        max-width: 500px;

        .alert-button-group-vertical {
            flex-direction: row;
            justify-content: space-around;
        }
    }
}

%dialog-btn {
    display: inline-block;
    // width: 32.3%;
}

.video-call-dialog--block-btn {
    @extend %dialog-btn;

    color: red !important;
}

.video-call-dialog--decline-btn {
    @extend %dialog-btn;

    color: red !important;
}

.video-call-dialog--accept-btn {
    @extend %dialog-btn;

    color: green !important;
}
