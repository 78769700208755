.cc-scroll-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;

    @media (max-width: 900px) {
        overflow-y: scroll;
    }

    &.default-overflow-scrolling {
        -webkit-overflow-scrolling: auto;
    }

    &:empty {
        display: none;
    }
}
