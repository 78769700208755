html {
    touch-action: manipulation;
}

.no-pointer-events {
    pointer-events: none;
}

button:focus {
    outline: 0 !important;
}
div:focus {
    outline: 0 !important;
}
