@use 'material-icons/iconfont/material-icons.css';

ion-button > {
    cc-svg-icon,
    i.material-icons,
    i.material-icons-outlined {
        &[slot='end'] {
            font-size: 1.4em;
            margin-inline-start: 0.3rem;
        }

        &[slot='start'] {
            font-size: 1.4em;
            margin-inline-end: 0.3rem;
        }
    }
}

html[dir='rtl'] {
    // RTL doesn't apply properly on the i tags for the icons so this must be here.
    // The values are taken from ionic styling here.
    ion-button > {
        cc-svg-icon,
        i.material-icons,
        i.material-icons-outlined {
            &[slot='end'] {
                margin-inline-end: 0.3rem;
                margin-inline-start: unset;
            }

            &[slot='start'] {
                margin-inline-start: 0.3rem;
                margin-inline-end: unset;
            }
        }
    }

    // RTL doesn't apply properly on the i tags for the icons so this must be here.
    // The values are taken from ionic styling here.
    ion-item > {
        cc-svg-icon,
        i.material-icons,
        i.material-icons-outlined {
            &[slot='end'] {
                margin-inline-end: 32px;
                margin-inline-start: unset;
            }

            &[slot='start'] {
                margin-inline-start: 32px;
                margin-inline-end: unset;
            }
        }
    }

    cc-svg-icon,
    i.material-icons,
    i.material-icons-outlined {
        &:not(.ignore-rtl) {
            transform: scaleX(-1);
        }
    }
}

i.material-icons,
i.material-icons-outlined,
i.material-icons-round {
    text-decoration: none !important;
}
