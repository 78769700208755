// Ionic are deprecating their media query utility classes in Ionic 4
// These are a replacement for those

$md-screen-width: 600px;

@media (max-width: $md-screen-width - 1) {
    .cc-hide-md-down {
        display: none !important;
    }
}

@media (min-width: $md-screen-width) {
    .cc-hide-md-up {
        display: none !important;
    }
}
