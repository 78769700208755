.emoji-mart {
    border: none;
}

.emoji-mart-emoji-native {
    > span {
        font-family:
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Twemoji Mozilla', 'Noto Color Emoji',
            'Android Emoji';
    }
}
