@use 'core/variables';

// This style should also be updated in the scroll-bar.directive for ionic components
:root {
    --cc-scrollbar-thumb-color: 39, 39, 39;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    background-color: rgba(var(--cc-scrollbar-thumb-color), 0);
    opacity: 0.2;
    border-radius: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(var(--cc-scrollbar-thumb-color), 0.5);
}

@media screen and (max-width: variables.$breakpoint-md) {
    ::-webkit-scrollbar-thumb {
        background-color: rgba(var(--cc-scrollbar-thumb-color), 0.8);
    }
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background-color: rgba(var(--cc-scrollbar-thumb-color), 0.8);
}
