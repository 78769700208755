input[type='text'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

// Temp solution to fix iOS native dispay issue where keyboard appears over inputs
// if they are positioned at the bottom of the page.
// TODO: Remove once Apple fixes
body {
    position: fixed !important;
}
