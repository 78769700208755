/*
* Colors
*/
$online-indicator: rgb(1, 175, 1);
$offline-indicator: rgb(148, 148, 148);

/*
* Breakpoints
*/
$breakpoint-xs: 370px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

/*
* Constants
*/

$sidebar-left-collapsed-width: 4rem;
