@use 'mixins';
@use 'core/variables';

@use '@ionic/angular/css/core.css';

@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';

@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';

:root {
    --ion-font-family: 'Roboto', sans-serif;
    // These will be overwritten by the branding service, however it's nice to have defaults and a good reference to help pick colors.
    // These assume a text color of black, and a card background of white
    --ion-text-color-step-50: #0d0d0d;
    --ion-text-color-step-100: #1a1a1a;
    --ion-text-color-step-150: #262626;
    --ion-text-color-step-200: #333333;
    --ion-text-color-step-250: #404040;
    --ion-text-color-step-300: #4d4d4d;
    --ion-text-color-step-350: #595959;
    --ion-text-color-step-400: #666666;
    --ion-text-color-step-450: #737373;
    --ion-text-color-step-500: #808080;
    --ion-text-color-step-550: #8c8c8c;
    --ion-text-color-step-600: #999999;
    --ion-text-color-step-650: #a6a6a6;
    --ion-text-color-step-700: #b3b3b3;
    --ion-text-color-step-750: #bfbfbf;
    --ion-text-color-step-800: #cccccc;
    --ion-text-color-step-850: #d9d9d9;
    --ion-text-color-step-900: #e6e6e6;
    --ion-text-color-step-950: #f2f2f2;
}

ion-modal {
    --border-radius: 8px;
}

ion-popover::part(content) {
    border-radius: 8px;
}

ion-card:not(.ion-no-radius) {
    border-radius: 6px;
}

ion-refresher {
    z-index: 99999;
}

ion-input,
ion-textarea {
    caret-color: black;
}

.incoming-video-call-modal {
    --width: 300px;
    --height: 335px;
}

ion-list:last-child {
    margin-bottom: 4em;
}

// Ionic tooltip styles.
tooltip-box {
    border-radius: 0.3rem !important;
    background: #d1d1d1 !important;
    color: var(--cc-default-text-color) !important;
    font-weight: normal !important;
    padding: 0 0.5rem !important;
    // height: initial !important;
}

ion-button {
    -webkit-appearance: none !important;
    text-transform: none;
}

ion-content {
    --background: none;
}

.ion-toolbar-indicated {
    ion-tab-button {
        border-bottom: 2px solid transparent;

        &.tab-selected {
            border-bottom: 2px solid var(--cc-default-text-color);
        }
    }
}

ion-tab-bar:not(.ion-color) {
    --background: transparent;

    ion-tab-button {
        --color: var(--ion-text-color-step-200);

        &.tab-selected,
        &:hover {
            --color-selected: var(--cc-default-text-color);
            font-weight: 500;
        }
    }
}

.alert-wrapper::part(content) {
    border-radius: 8px;
    --background: var(--cc-card-background);

    .alert-button {
        color: var(--cc-default-text-color);
    }
}

.loading-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.cc-hide-scrollbar {
    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important; /* Firefox */

    &::-webkit-scrollbar {
        display: none !important;
    }
}

// For fill="clear" buttons over the primary color, use this class for hover / focus styling
.clear-on-primary {
    --color: var(--cc-text-on-primary-color);
    --color-focused: var(--color);
    --color-activated: var(--color);

    // Copied from the hover effect of an ion item
    --background-hover: var(--ion-item-background-hover, rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.04));
    --background-focused: var(--cc-background-color);
}

// Used via color="default" on an ion-button.
.ion-color-default {
    --color: var(--cc-default-text-color);
    --color-focused: var(--color);
    --color-activated: var(--color);

    --ion-color-contrast: var(--cc-default-text-color);
    --ion-color-contrast-rgb: var(--cc-default-text-color-rgb);
}

.ion-focused {
    filter: brightness(80%);
    outline: 1px solid var(--cc-text-on-primary-color);
}

.ion-item-reset {
    @include mixins.ion-padding-all(0);
    @include mixins.ion-inner-padding-all(0);
    font-size: 1em;
    --min-height: 0;

    > *:not([slot]) {
        width: 100%;
    }
}

.ion-item-no-padding {
    @include mixins.ion-padding-all(0);
    @include mixins.ion-inner-padding-all(0);
}

.ion-popover-fluid-width {
    --width: auto;
}

ion-skeleton-text {
    margin: 0;
    line-height: inherit;
}

.action-sheet-group {
    --background: var(--cc-card-background);
    --button-background: var(--cc-card-background);
    --color: var(--cc-default-text-color);
    --button-color: var(--cc-default-text-color);
}

ion-checkbox {
    .checkbox-title {
        margin: 0;
        white-space: normal;
    }

    .checkbox-message {
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--ion-text-color-step-400);
        white-space: normal;
    }
}
