// I really, REALLY didn't want to do this. But the iOS interface has a lot of visual bugs around the safe area.
// Sadly, we're just going to have to add to this whenever a new one gets spotted, and make sure we test new pages on iOS PWA.

.meetings-page__container,
cc-meeting-availability-tab,
.poll-intermission,
cc-survey-page > ion-content,
cc-poll-intermission,
cc-countdown-timer,
cc-poll,
.people__container,
.session-live-stream-tabs__content,
.post-modal,
.abstract-detail-tabs__content,
.qanda-page__container,
.gamification-controls__fab,
cc-chat-page > cc-chat-detail {
    padding-bottom: env(safe-area-inset-bottom) !important;
}
