ion-item.form-control {
    &:not([fill='outline']) {
        --padding-start: 0;
    }

    --highlight-color-invalid: var(--ion-color-danger);
    --highlight-color-valid: var(--ion-color-primary);
    margin-bottom: 1.5rem;
    position: relative;
    overflow: visible;

    ion-label {
        color: var(--cc-default-text-color) !important;
    }

    &.ion-untouched {
        --highlight-color-invalid: var(--ion-color-primary);
    }

    &.checkbox {
        --padding-start: 6px;
        ion-label {
            padding: 0 0.5rem;
            font-size: 0.8rem;
        }
    }

    ion-textarea {
        min-height: 6rem;
    }
}

// To support form controls that aren't items (Ionic 7)
.form-control {
    margin-bottom: 1.5rem;
}

ion-item.rounded-form-control {
    --background: transparent;
    --border-color: transparent;
    --show-full-highlight: 0;
    --color-hover: unset;
    --border-width: 0;
    --highlight-background: var(--cc-accent-color);
    --background-hover-opacity: 0;
    --ripple-color: transparent;

    border: 1px solid rgba(black, 0.12);
    border-radius: 6px;
    overflow: visible;
    position: relative;
    transition: all 100ms;

    &:focus-within {
        box-shadow: inset 0 0 0 2px var(--cc-accent-color);
    }

    // TODO: Remove this once happy nothing is using this anymore, most inputs should have labels set on the input itself now
    ion-label {
        background-color: var(--cc-form-input-label-background, --cc-background-color);
        position: relative;
        top: -15px;
        left: -8px;
        align-self: flex-start !important;
        padding: 0 6px;
        font-size: 14px !important;
        color: var(--cc-accent-color) !important;
        opacity: 1 !important;
    }

    &.item-interactive-disabled ion-label::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--cc-form-input-label-background, #f5f5f5);
        filter: opacity(0.5);
        top: 0;
        left: 0;
    }

    ion-input {
        --padding-bottom: 14px !important;
        --padding-top: 2px !important;

        .label-text-wrapper {
            background-color: var(--cc-form-input-label-background, var(--ion-item-background));
            position: relative;
            top: -16px;
            left: -8px;
            align-self: flex-start !important;
            padding: 0 6px;
            font-size: 14px !important;
            color: var(--cc-accent-color) !important;
            opacity: 1 !important;
        }

        &.input-disabled {
            opacity: 1;
        }

        &.input-disabled .label-text-wrapper {
            color: var(--cc-light-grey) !important;
        }
    }

    ion-input,
    ion-select {
        --highlight-color-valid: transparent;
    }

    ion-select {
        padding-bottom: 15px;
        min-height: 32px;

        &::part(icon) {
            transform: translate3d(0px, 8px, 0px);
        }

        &::part(label) {
            background-color: var(--cc-form-input-label-background, #f5f5f5);
            position: relative;
            top: -16px;
            left: -8px;
            align-self: flex-start !important;
            padding: 0 6px;
            font-size: 14px !important;
            color: var(--cc-accent-color) !important;
            opacity: 1 !important;
        }

        &.select-expanded {
            &::part(icon) {
                transform: translate3d(0px, 7px, 0px) rotate(180deg);
                color: unset;
            }
        }

        &.select-disabled {
            opacity: 1;

            &::part(label) {
                color: var(--cc-light-grey) !important;
            }

            &::part(placeholder) {
                color: var(--cc-light-grey) !important;
            }

            &::part(icon) {
                color: var(--cc-light-grey) !important;
            }
        }
    }
}

ion-item.invisible-form-control {
    --padding-start: 0;
    --background: transparent;
    --border-width: 0;
    --show-full-highlight: 0;
    --highlight-background: var(--cc-accent-color);

    textarea {
        --padding-top: 0;
    }
}

.sc-ion-textarea-md-h {
    &:not([fill='outline']) {
        --highlight-color: none !important;
        --border-color: none !important;
    }

    &.textarea-fill-outline {
        --border-color: var(--cc-light-grey);
        --highlight-color-focused: var(--cc-accent-color-tint);
        --highlight-color-invalid: var(--cc-warn-color);
        --highlight-color-valid: var(--cc-accent-color);

        &:hover {
            --border-color: var(--cc-accent-color-tint);
        }
    }

    // Ionic 7 introduced a min height to textareas.
    // We don't want that as we use them as one line boxes that expand quite often.
    &:not(.legacy-textarea) {
        min-height: unset;
        --padding-top: 10px;
        --padding-bottom: 10px;
    }
}
.sc-ion-input-md-h {
    &:not([fill='outline']) {
        --highlight-color: none !important;
    }

    &.input-fill-outline {
        --border-color: var(--cc-light-grey);
        --highlight-color-focused: var(--cc-accent-color-tint);
        --highlight-color-invalid: var(--cc-warn-color);
        --highlight-color-valid: var(--cc-accent-color);

        &:hover {
            --border-color: var(--cc-accent-color-tint);
        }
    }

    &.input-disabled {
        pointer-events: none;
    }
}

ion-select.select-fill-outline {
    --highlight-color-focused: var(--cc-accent-color-tint);
    --highlight-color-invalid: var(--cc-warn-color);
    --highlight-color-valid: var(--cc-accent-color);

    &:hover {
        --border-color: var(--cc-accent-color-tint);
    }

    &.input-disabled {
        pointer-events: none;
    }
}
