@use './scss/wysiwyg';
@use './scss/vendor';
@use './scss/typography';
@use './scss/touch';
@use './scss/quill';
@use './scss/modals';
@use './scss/layout';
@use './scss/ionic';
@use './scss/icons';
@use './scss/forms';
@use './scss/bootstrap';
@use './scss/helpers';
@use './scss/scrollbars';
@use './scss/global-hacks';
@use './scss/rtl';

@use '../node_modules/leaflet/dist/leaflet.css';
@use './scss/branding';
@use './scss/media-queries';
@use './scss/lists';
@use './scss/functions';
@use './scss/legacy/_all' as legacy-all;
@use './scss/core/_all' as core-all;
@use './scss/shared/_all' as shared-all;
@use './scss/modules/_all' as modules-all;

// Overlay Loading (needs to be global style as exists outside of the components
.cc-overlay-loading {
    --background: var(--cc-accent-color);
    --spinner-color: var(--cc-text-on-accent-color);
    color: var(--cc-text-on-accent-color);
}

/*
*
* !!!! DO NOT MERGE THIS INTO THE MASTER BRANCH !!!!
*
*/
.hide-old {
    display: none !important;
}

// Dev Styles
pre {
    background: #fff;
    font-family: monospace;
}

.sidebar-left-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

/*------ Set default font for various elements ------*/
html,
body,
p,
textarea,
input,
h1,
h2,
h3,
ion-card-title,
ion-card-title span,
span.session-title {
    font-family: var(--font-family, sans-serif), sans-serif;
    --ion-font-family: var(--font-family), sans-serif;
}
