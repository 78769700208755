// VideJs Overrides

.vjs-poster {
    background-size: cover !important;
}

.video-player-dimensions {
    width: 100% !important;
    height: auto !important;
}

.vjs-error-display.vjs-modal-dialog .vjs-modal-dialog-content {
    display: none !important;
}

.vjs-control-bar {
    .vjs-icon-placeholder,
    .vjs-play-progress:before,
    .vjs-volume-level:before {
        font-family: 'VideoJS' !important;
        --ion-font-family: 'VideoJS' !important;
    }
}

.vjs-error .vjs-error-display:before {
    display: none !important;
}
