:root {
    /*
    * These colors are editable via the CMS branding section
    */
    // Theme colors (generated in branding.service)
    --cc-primary-color: #01579b;
    --cc-accent-color: #73b0e1;
    --cc-success-color: #00e096;
    --cc-info-color: #0095ff;
    --cc-warn-color: #f44336;

    // App Background Color
    --cc-background-color: #f5f5f5;

    // Text colours (generated in branding.service)
    --cc-default-text-color: #222222;
    --cc-text-on-primary-color: #fff;
    --cc-text-on-accent-color: #fff;
    --cc-text-on-warn-color: #fff;
    --cc-link-color: #01579b;

    // UI Elements
    --cc-card-background: #fff;
    --cc-alert-background: #fff;
    --cc-company-card-background: #fff;

    --cc-border-color: #e9e9e9;

    --cc-leaderboard-card-background: var(--cc-accent-color);
    --cc-leaderboard-card-color: var(--cc-text-on-accent-color);

    --cc-extra-light-grey: #e6e6e6;
    --cc-light-grey: #c0c0c0;
    --cc-mid-grey: #7f7f7f;
    --cc-dark-grey: #494949;
    --cc-peer-chat-message-background: var(--cc-accent-color);
    --cc-peer-chat-message-text: var(--cc-text-on-accent-color);
}

@mixin cc-primary-background() {
    background: var(--cc-primary-color);
    color: var(--cc-text-on-primary-color);
}

@mixin cc-accent-background() {
    background: var(--cc-accent-color);
    color: var(--cc-text-on-accent-color);
}

body {
    color: var(--cc-default-text-color) !important;
}

// Typography
//This is neccessary to ensure the custom buttons in Quilljs dont have their colour overwritten
a:not(:has(> cc-custom-html-element[type='button'])) {
    color: var(--cc-link-color) !important;
}

.text-primary {
    color: var(--cc-primary-color) !important;
}

.text-warn {
    color: var(--cc-warn-color) !important;
}

.text-accent {
    color: var(--cc-accent-color) !important;
}

// UI Elements
.cc-primary {
    @include cc-primary-background();
}

.cc-accent {
    @include cc-accent-background();
}

// Taken from cc-theme.html that was injected into the head of the page

md-toast {
    position: fixed !important;
}

md-toast.md-success-theme .md-toast-content {
    @include cc-primary-background();
}

.primary-color {
    color: var(--cc-primary-color);
}

.companies-filter--option {
    color: var(--cc-default-text-color);
}

.companies-filter--option[selected] {
    color: var(--cc-default-text-color);
}

// Ionic Framework Styles
ion-card {
    --ion-item-background: var(--cc-card-background);
}

// Ionic Variables (TODO - Replace Theme with these once fully migrated)
// The rgb, shades and tint colours will need to be generated with javascript on the cms
@mixin override-ion-colors-with-cc {
    --ion-color-primary: var(--cc-primary-color);
    --ion-color-primary-rgb: var(--cc-primary-color-rgb);
    --ion-color-primary-contrast: var(--cc-primary-color-contrast);
    --ion-color-primary-contrast-rgb: var(--cc-primary-color-contrast-rgb);
    --ion-color-primary-shade: var(--cc-primary-color-shade);
    --ion-color-primary-tint: var(--cc-primary-color-tint);

    --ion-color-accent: var(--cc-accent-color);
    --ion-color-accent-rgb: var(--cc-accent-color-rgb);
    --ion-color-accent-contrast: var(--cc-accent-color-contrast);
    --ion-color-accent-contrast-rgb: var(--cc-accent-color-contrast-rgb);
    --ion-color-accent-shade: var(--cc-accent-color-shade);
    --ion-color-accent-tint: var(--cc-accent-color-tint);

    --ion-color-danger: var(--cc-warn-color);
    --ion-color-danger-rgb: var(--cc-warn-color-rgb);
    --ion-color-danger-contrast: var(--cc-warn-color-contrast);
    --ion-color-danger-contrast-rgb: var(--cc-warn-color-contrast-rgb);
    --ion-color-danger-shade: var(--cc-warn-color-shade);
    --ion-color-danger-tint: var(--cc-warn-color-tint);

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-text-color: var(--cc-default-text-color);
    --ion-item-color: var(--cc-default-text-color);

    --ion-item-background: var(--cc-card-background);
}

:root {
    @include override-ion-colors-with-cc;
}

.ion-color-primary {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-accent {
    --ion-color-base: var(--ion-color-accent);
    --ion-color-base-rgb: var(--ion-color-accent-rgb);
    --ion-color-contrast: var(--ion-color-accent-contrast);
    --ion-color-contrast-rgb: var(--ion-color-accent-contrast-rgb);
    --ion-color-shade: var(--ion-color-accent-shade);
    --ion-color-tint: var(--ion-color-accent-tint);
}

.ion-color-danger {
    --ion-color-base: var(--ion-color-danger);
    --ion-color-base-rgb: var(--ion-color-danger-rgb);
    --ion-color-contrast: var(--ion-color-danger-contrast);
    --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
    --ion-color-shade: var(--ion-color-danger-shade);
    --ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-dark {
    --ion-color-base: var(--ion-color-dark);
    --ion-color-base-rgb: var(--ion-color-dark-rgb);
    --ion-color-contrast: var(--ion-color-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-shade);
    --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-sidebar {
    --color: var(--cc-text-on-sidebar-color);
    --color-focused: var(--color);
    --color-activated: var(--color);

    --ion-color-base: var(--cc-sidebar-color);
    --ion-color-base-rgb: var(--cc-sidebar-color-rgb);
    --ion-color-contrast: var(--cc-sidebar-color-contrast);
    --ion-color-contrast-rgb: var(--cc-sidebar-color-contrast-rgb);
    --ion-color-shade: var(--cc-sidebar-color-shade);
    --ion-color-tint: var(--cc-sidebar-color-tint);
}

.cc-person-button {
    --background-hover: rgba(239, 239, 239, 0.2);
}

ion-card {
    --color: var(--cc-default-text-color);
}

// Allow full width background images
ion-menu {
    background-color: transparent !important;
    --background: transparent !important;
    box-shadow: none !important;
}

.cc-sidebar-left-tooltip {
    background: var(--cc-primary-color) !important;
    color: var(--cc-text-on-primary-color) !important;

    &::after {
        content: none !important;
    }
}
