p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:first-child {
        margin-top: 0;
    }
}

.bold {
    font-weight: bold;
}
