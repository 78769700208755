quill-editor .ql-container {
    border: none !important;
}
.ql-font {
    &-arial {
        font-family: arial, sans-serif;
    }

    &-times {
        font-family:
            Times New Roman,
            serif;
    }

    &-courier-new {
        font-family:
            Courier New,
            monospace;
    }

    &-courier {
        font-family: Courier, monospace;
    }

    &-verdana {
        font-family: Verdana, sans-serif;
    }

    &-georgia {
        font-family: Georgia, serif;
    }

    &-garamond {
        font-family: Garamond, serif;
    }

    &-palatino {
        font-family: Palatino, serif;
    }

    &-bookman {
        font-family: bookman-jf-pro, serif;
    }

    &-trebuchet-ms {
        font-family: Trebuchet, sans-serif;
    }

    &-arial-black {
        font-family: 'Arial Black', sans-serif;
        font-weight: 900;
    }

    &-impact {
        font-family: impact, sans-serif;
    }
}
