@mixin video-aspect-ratio() {
    width: 100%;
    height: 400px;

    @supports (aspect-ratio: 1) {
        height: auto;
        aspect-ratio: 16 / 9;
    }
}

.wysiwyg-content {
    img {
        max-width: 100%;
    }

    .ql-video {
        border: none;
        @include video-aspect-ratio();
    }

    cc-video .cc-video-container {
        @include video-aspect-ratio();
    }

    a:has(> cc-custom-html-element[type='button']) {
        text-decoration: none !important;
    }
}
