@use 'core/variables';

html,
body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

:root {
    --sidebar-left-open: 320px;
    --sidebar-left-collapsed-width: 4rem;
    --side-max-width: var(--sidebar-left-open);

    ion-split-pane {
        &.collapsed {
            --content-full-width: calc(100vw - var(--sidebar-left-collapsed-width));
        }

        &:not(.collapsed) {
            --content-full-width: calc(100vw - var(--sidebar-left-open));
        }
    }
}

*[contenteditable] {
    -webkit-user-select: auto !important;
}

a,
span.cursor-pointer {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.container,
cc-content div.cc-content-content {
    width: 90%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 780px !important;
    animation: none;
    padding: 8px;
    box-sizing: border-box;

    @media only screen and (max-width: variables.$breakpoint-md) {
        width: 100%;
        padding: 0;
    }
}

.scroll-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;

    @media (max-width: 900px) {
        overflow-y: scroll;
    }

    &.default-overflow-scrolling {
        -webkit-overflow-scrolling: auto;
    }

    &:empty {
        display: none;
    }
}

.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

blockquote {
    border-left: 4px solid;
    padding-inline-start: 1.2rem;
    margin-inline-start: 0;
}

main {
    background: transparent !important;
    &:focus-visible {
        outline: none;
    }
}

.fullscreen-centered-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

/* Auto applied by the auto-line-clamp directive. Could be manually used by also specifying the line clamp */
.line-clamp-base {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* The support guys use the below still */
.padding-top-0 {
    padding-top: 0px;
}

.height-100 {
    height: 100%;
}
