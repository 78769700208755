ion-list.cc-list-cards {
    background: transparent;

    ion-item {
        cursor: pointer;
        margin: 0.5em;
        border-radius: 0.25em;
        --min-height: 4.5em;
        background-color: var(--cc-card-background);
    }
}
